import { $axios } from '~cognito/plugins/axios'

class RacersTrack {
  id: number
  name: string
  nw_lat: number
  nw_lon: number
  se_lat: number
  se_lon: number

  constructor(source?: Partial<RacersTrack>) {
    this.id = 0
    this.name = ''
    this.nw_lat = 0
    this.nw_lon = 0
    this.se_lat = 0
    this.se_lon = 0
    Object.assign(this, source)
  }

  async getTracks(): Promise<RacersTrack[]> {
    const data = await $axios.get('/api/v1/racers/venue/raceControlTrackList')
    return data.data
  }
}

export { RacersTrack }
