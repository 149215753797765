<template>
  <div class="mx-auto max-w-lg p-4">
    <div class="py-4">
      <img class="mx-auto" src="../images/logo.png">
    </div>
    <div v-for="(track, index) in tracks" :key="track.id" class="flex flex-col overflow-hidden p-2 text-xl" :class="index % 2 ? 'bg-white' : 'bg-brand-50'">
      <div class="text-lg">
        {{ track.name }}
      </div>
      <div class="flex justify-end gap-2">
        <cgn-button color-info :url="`/view/${track.id}`">
          View
        </cgn-button>
        <cgn-button color-brand :url="`/drive/${track.id}`">
          Drive
        </cgn-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { RacersTrack } from '~/models/RacersTrack'

const tracks = ref<RacersTrack[]>([])

onMounted(async () => {
  tracks.value = await new RacersTrack().getTracks()
})
</script>
