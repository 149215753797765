<template>
  <footer aria-labelledby="footer-heading" class="bg-gray-50 dark:bg-darkbg-800">
    <div id="footer-heading" class="sr-only">
      Footer
    </div>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="border-t border-gray-100 dark:border-gray-800 py-10 text-center">
        <p class="text-sm text-gray-500">
          &copy; {{ year }}. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const year = ref(new Date().getFullYear())
</script>
