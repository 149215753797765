import { config } from '../cognitocmsapi/default_config.js'

config.devURL = `https://racers.${config.devbase}`
config.prodURL = 'https://racers.world'
config.siteURL = 'https://racecontrol.racers.world'
config.baseURL = config.devbase == '' ? config.prodURL : config.devURL

config.isEcommerce = false
// config.sentryDsn = 'https://68125b41223a49bfaef13a3a42464a9f@glitchtip.logger.jm1.au/9'

export { config }
