import OpenLayersMap from 'vue3-openlayers'
import type { UserModule } from '~/types'

import 'vue3-openlayers/dist/vue3-openlayers.css'

export const install: UserModule = ({ isClient, app }) => {
  if (isClient) {
    app.use(OpenLayersMap)
  }
}
